import Vue from 'vue'
import VueRouter from 'vue-router'
import user from "@/store/user";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/home/Home.vue'),
        meta: {
            requireAuth: false,
            noChangeWindow: false,
            noMenu: false,
            title: '商道融绿',
            width: '1200px',
            height: '800px',
            view_name: '_Demo/Introduction',
            metaInfo:{
                web_title: '商道融绿-碳价风险压力测试模型在线工具-Demo',
                keywords: '碳价风险压力测试',
                description: '商道融绿基于多个全球先进综合评估模型的未来排放预测数据以及国内权威碳排放核算数据，通过模拟各省份在不同产业部门的能源结构、碳强度下降速率、能耗双控目标完成情况、区域集中度、过去五年碳排放量年均变化值等动态演变进程，开发了在不同社会经济情景和碳浓度目标约束下中国分省份分产业部门的2020-2060年碳排放预测数据集。',
                width: '1200px',
                height: '800px',
                
            }
        },

    },
    {
        path: '/login',
        name: 'SignIn',
        component: () => import('../views/user-sign/SignIn.vue'),
        meta: {
            title: '商道融绿',
            requireNotAuth: true,
            noNav: true,
            noMenu: true,
        }
    },
    {
        path: '/carbon-emissions-calculator.html',
        name: '商道资产组合碳排放测算主页面',
        component: () => import('../views/home/Home.vue'),
        meta: {
            requireAuth: false,
            noChangeWindow: true,
            title: '商道资产组合碳排放测算分析工具',
            noMenu: false,
            view_name:  '_16872451007500/sheet0',
            width: '1200px',
            height: '800px',
            metaInfo:{
                web_title: '商道资产组合碳排放测算分析工具',
                keywords: '碳排放 资产组合 测算',
                description: '商道融绿基于多个全球先进综合评估模型的未来排放预测数据以及国内权威碳排放核算数据，通过模拟各省份在不同产业部门的能源结构、碳强度下降速率、能耗双控目标完成情况、区域集中度、过去五年碳排放量年均变化值等动态演变进程，开发了在不同社会经济情景和碳浓度目标约束下中国分省份分产业部门的2020-2060年碳排放预测数据集。',

            }
        },

    },
    {
        path: '/carbon-emissions-calculator/users',
        name: '用户管理',
        component: () => import('../views/user-manage/Manage.vue'),
        meta: {
            requireAuth: false,
            noChangeWindow: false,
            // title: '商道资产组合碳排放测算分析工具',
            noMenu: true,
            // view_name:  '_16872451007500/sheet0',
            // width: '1200px',
            // height: '800px',
            metaInfo:{
                web_title: '商道融绿用户管理',
                keywords: '商道融绿 用户管理',
                description: '商道融绿用户管理页面',

            }
        },

    },
    {
        path: '/carbon-emissions-calculator/upload',
        name: '文件上传',
        component: () => import('../views/caculation/upload.vue'),
        meta: {
            requireAuth: false,
            noChangeWindow: false,
            // title: '商道资产组合碳排放测算分析工具',
            noMenu: true,
            // view_name:  '_16872451007500/sheet0',
            // width: '1200px',
            // height: '800px',
            metaInfo:{
                web_title: '商道融绿用户上传页面',
                keywords: '商道融绿 上传页面',
                description: '商道融绿文件上传页面',

            }
        },

    },
    {
        path: '/*',
        name: 'PageNotFound',
        component: () => import('../views/error/PageNotFound'),
        meta: {
            noNav: true,
            noMenu: true,
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined?'商道融绿':to.meta.title
	// 要进行导航守卫的路径值
    if (to.path === '/login') {
        localStorage.setItem("preRoute", router.currentRoute.fullPath);
    }


    const userInfo = user.getters.getUser(user.state())
    if (!userInfo && to.meta.requireAuth) {
        next({
            name: 'SignIn',
        })
    }else{
    next()
    }

})


export default router