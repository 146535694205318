<template>
  <div id="app">
   <!-- <pageHeader v-if="!$route.meta.noNav"/> -->
   <router-view> </router-view>
  </div>
</template>

<script>
// import PageHeader from "./components/header/pageHeader";
import user from "@/store/user";
//import ActivityWindowVue
//import ActivityWindowVue from './components/views/ActivityWindow.vue';

// import navMenu from './components/nav/navMenu.vue';
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}


export default {
  name: 'App',
  components: {
    // PageHeader,
    //ActivityWindowVue
  },
  data() {
    return {
      isLogin: false,
    }
  },
  mounted() {
    const userInfo = user.getters.getUser(user.state())
    this.isLogin = !!userInfo;
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
