import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import GlobalInfo from './GlobalInfo'

import './theme.scss'
Vue.use(ElementUI);




// axios
Vue.prototype.$axios = axios
axios.defaults.baseURL = GlobalInfo.backUrl;
//axios.defaults.withCredentials = true

import * as Tableau from 'tableau-api-js'
Vue.prototype.$tableau = Tableau


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
